import {
  CreditScore,
  Dashboard,
  Leaderboard,
  ManageAccounts,
  NotificationsRounded,
  People,
  Person,
  Support,
} from "@mui/icons-material";
const UniversityMenuItems = [
  {
    key: "1",
    title: "Dashboard",
    icon: <Dashboard className="iconColor" />,
    route: "/dashboard",
  },
  {
    key: "2",
    title: "Univer Leads",
    icon: <People className="iconColor" />,
    route: "/leads",
  },
  {
    key: "9",
    title: "Unlocked Leads",
    icon: <Person className="iconColor" />,
    route: "/normal-leads",
  },
  {
    key: "4",
    title: "Bought Out Leads",
    icon: <Leaderboard className="iconColor" />,
    route: "/exclusive-leads",
  },
  {
    key: "7",
    title: "Credits",
    icon: <CreditScore className="iconColor" />,
    route: "/credits",
  },
  {
    key: "8",
    title: "University Support",
    icon: <Support className="iconColor" />,
    route: "/university-support",
  },
  {
    key: "6",
    title: "Notifications",
    icon: <NotificationsRounded className="iconColor" />,
    route: "/notifications",
  },
  {
    key: "5",
    title: "Account Settings",
    icon: <ManageAccounts className="iconColor" />,
    route: "/account-settings",
  },
];
export default UniversityMenuItems;
