// import { useMediaQuery } from "@mui/material";
// import { useIsMounted } from "hooks";

// const { createContext, useState, useEffect } = require("react");

// export const AppContext = createContext();

// const AppContextProvider = ({ children }) => {
//   const [user, setUser] = useState({ role: "superadmin" });
//   const [isDarkTheme, setIsDarkTheme] = useState(false);
//   const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
//   const { isMounted } = useIsMounted();
//   const login = (credentials) => {
//     try {
//       isMounted.current && setUser(credentials);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     isMounted.current && setIsDarkTheme(prefersDarkMode);
//   }, [prefersDarkMode, isMounted]);

//   return (
//     <AppContext.Provider
//       value={{ user, setUser, login, isDarkTheme, setIsDarkTheme }}
//     >
//       {children}
//     </AppContext.Provider>
//   );
// };

// export default AppContextProvider;

import { auth, database } from "configs/firebase";
import { useIsMounted } from "hooks";
const { createContext, useState, useEffect } = require("react");

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(true);
  const [snack, setSnack] = useState({
    boolean: false,
    message: "",
    severity: "success",
  });
  const { isMounted } = useIsMounted();

  const login = async (email, password) => {
    try {
      return await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      snackBarOpen("Your username or password is incorrect", "error");
      return;
    }
    // isMounted.current && setUser(credentials);
  };
  const signUp = async (email, password) => {
    try {
      return await auth.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      new Error(error);
    }
  };
  const logout = () => {
    try {
      auth.signOut();
      // console.log("ffff");
      return setUser({});
      // setCurrentUserData({});
    } catch (error) {
      new Error(error);
    }
  };
  const snackBarClose = async () => {
    setSnack({ message: "", severity: "success", boolean: false });
  };
  const snackBarOpen = (message, severity) => {
    console.log(message);
    setSnack({
      message,
      severity: severity ? severity : "success",
      boolean: true,
    });
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (!currentUser && isMounted.current) return setLoader(false);
      // console.log(currentUser.uid);
      try {
        database.ref(`Users/${currentUser.uid}`).on("value", (snapshot) => {
          isMounted.current && setUser(snapshot.val());
          isMounted.current && setLoader(false);
        });
      } catch (error) {
        new Error(error);
      }
    });
    return unsubscribe;
  }, [isMounted]);
  // console.log(user);

  const value = {
    signUp,
    login,
    user,
    setUser,
    logout,
    snackBarClose,
    snack,
    snackBarOpen,
    loader,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
