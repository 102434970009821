import { PanelLayout } from "layouts";
import {
  Credits,
  ExclusiveLeads,
  Leads,
  MyLeads,
  Notifications,
  Settings,
  UniversityDashboard,
  UniversitySupport,
} from "pages";
import { Routes, Route } from "react-router-dom";
const UniversityRoutes = () => {
  return (
    <PanelLayout>
      <Routes>
        <Route path="/" element={<UniversityDashboard />} />
        <Route path="/dashboard" element={<UniversityDashboard />} />
        <Route path="/account-settings" element={<Settings />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/leads" element={<Leads />} />
        <Route path="/normal-leads" element={<MyLeads />} />
        <Route path="/exclusive-leads" element={<ExclusiveLeads />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/university-support" element={<UniversitySupport />} />
      </Routes>
    </PanelLayout>
  );
};

export default UniversityRoutes;
