import {
  AccountBalance,
  Approval,
  ContactMail,
  CreditScore,
  Dashboard,
  ManageAccounts,
  NotificationsRounded,
  Person,
  Support,
} from "@mui/icons-material";

const MenuItems = [
  {
    key: "1",
    title: "Dashboard",
    icon: <Dashboard className="iconColor" />,
    route: "/dashboard",
  },
  {
    key: "8",
    title: "Universities",
    icon: <AccountBalance className="iconColor" />,
    route: "/universities",
  },
  {
    key: "21",
    title: "Universities Request",
    icon: <Approval className="iconColor" />,
    route: "/requested-universities",
  },
  // {
  //   key: "22",
  //   title: "Normal Leads",
  //   icon: <People className="iconColor" />,
  //   route: "/requested-normal-leads",
  // },
  // {
  //   key: "23",
  //   title: "Exclusive Leads",
  //   icon: <Leaderboard className="iconColor" />,
  //   route: "/requested-exclusive-leads",
  // },
  {
    key: "11",
    title: "Request Credits",
    icon: <CreditScore className="iconColor" />,
    route: "/credit-management",
  },
  {
    key: "15",
    title: "Student",
    icon: <Person className="iconColor" />,
    route: "/student-management",
  },
  {
    key: "9",
    title: "Contacts",
    icon: <ContactMail className="iconColor" />,
    route: "/contacts",
  },
  {
    key: "10",
    title: "Supports",
    icon: <Support className="iconColor" />,
    route: "/supports",
  },
  // {
  //   key: "4",
  //   title: "Users",
  //   icon: <People />,
  //   route: "/users",
  // },
  // {
  //   key: "5",
  //   title: "Products",
  //   icon: <ShoppingBasket className="iconColor" />,
  //   route: "/products",
  // },
  // {
  //   key: "6",
  //   title: "Categories",
  //   icon: <LocalOffer />,
  //   route: "/categories",
  // },
  // {
  //   key: "7",
  //   title: "Manage FAQs",
  //   icon: <Quiz className="iconColor" />,
  //   route: "/manage-faqs",
  // },
  {
    key: "3",
    title: "Notifications",
    icon: <NotificationsRounded className="iconColor" />,
    route: "/notifications",
  },
  {
    key: "2",
    title: "Account Settings",
    icon: <ManageAccounts className="iconColor" />,
    route: "/account-settings",
  },
];

export default MenuItems;
